import BackgroundImage from "gatsby-background-image";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { renderDynamicComponents } from "../components";
import RelatedArticles from "../components/relatedArticles";
import { SidebarDynamic } from "../components/sidebar";
import Breadcrumb from "../modules/breadcrumb";
import Layout from "../modules/layout";
import News from "../modules/news";
import SEO from "../modules/seo";

const DetailPage = ({ pageContext }) => {
  const {
    name,
    contentComponents,
    node_locale,
    settings,
    breadcrumbs,
    showBreadcrumbNavigation,
    showNews,
    showRelatedArticles,
    topic = [],
    focusOfWork,
    region,
    childPages,
    image,
    xPosition = "",
    yPosition = "",
  } = pageContext;
  const siteSettings = settings;
  const sidebar = contentComponents.find(
    component => component.internal.type === "ContentfulComponentSidebar"
  );
  let tagInfo;

  if (showRelatedArticles) {
    tagInfo = {
      topic,
      focusOfWork,
      region,
      node_locale,
    };
  }

  return (
    <Layout siteSettings={siteSettings}>
      <SEO
        title={`${siteSettings.siteName} - ${name}`}
        description={siteSettings.siteDescription}
        author={siteSettings.siteAuthor}
        ogImage={image?.imageLandscape?.fluid?.src}
      />
      {image && (
        <BackgroundImage
          Tag="section"
          className={`pageHeader`}
          fluid={image.imageLandscape.fluid}
          style={{
            backgroundPosition: `${xPosition} ${yPosition}`,
          }}
        ></BackgroundImage>
      )}
      <Breadcrumb
        visible={showBreadcrumbNavigation}
        breadcrumbs={breadcrumbs}
        locale={node_locale}
      ></Breadcrumb>
      <Container fluid className={"detailPage"}>
        <h1 className={"mb-3"}>{name}</h1>
        <Row className={"detailPage--content"}>
          {sidebar && (
            <Col lg={4} as="aside">
              <SidebarDynamic
                sidebar={sidebar}
                locale={node_locale}
                childPages={childPages}
              ></SidebarDynamic>
            </Col>
          )}
          <Col lg={sidebar ? 8 : 12} as="section">
            {contentComponents &&
              renderDynamicComponents(contentComponents, node_locale)}
          </Col>
        </Row>
      </Container>
      <News node_locale={node_locale} settings={settings} visible={showNews} />
      {tagInfo && <RelatedArticles tagInfo={tagInfo} />}
    </Layout>
  );
};

export default DetailPage;
